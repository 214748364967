function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  admin: '/admin',
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    register: path(ROOTS.auth, '/register'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify'),
  },
  watchPage:
    '/watch-livestream/:programId/:programLivestreamId/session/:sessionId',
  watchVideoLearning: '/watch-video-learning/:onlineCourseId/topic/:topicId',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  notFound: '/not-found',
  error: '/error',
};

export const PATH_ADMIN = {
  root: ROOTS.admin,
  generalSection: {
    root: path(ROOTS.admin, '/dashboard'),
    dashboard: path(ROOTS.admin, '/dashboard'),
    alumniStory: {
      root: path(ROOTS.admin, '/alumni-story'),
      list: path(ROOTS.admin, '/alumni-story/list'),
      create: path(ROOTS.admin, '/alumni-story/create'),
    },
    walkInEnquiry: {
      root: path(ROOTS.admin, '/walk-in-enquiry'),
      create: path(ROOTS.admin, '/walk-in-enquiry/create'),
      edit: path(ROOTS.admin, '/walk-in-enquiry/:walkInEnquiryId/edit'),
    },
    onlineEnquiry: {
      root: path(ROOTS.admin, '/online-enquiry'),
    },
    registrationEnquiry: {
      root: path(ROOTS.admin, '/registration-enquiry'),
    },
    corporateEnquiry: {
      root: path(ROOTS.admin, '/corporate-enquiry'),
    },
  },
  programSection: {
    root: path(ROOTS.admin, '/program'),
    program: {
      root: path(ROOTS.admin, '/program/list'),
      list: path(ROOTS.admin, '/program/list'),
      attendees: path(ROOTS.admin, '/program/attendees'),
      studentAttendance: path(ROOTS.admin, '/program/student-attendance'),
      syllabus: path(ROOTS.admin, '/program/syllabus'),
    },
    videoLearning: {
      root: path(ROOTS.admin, '/program/video-learning'),
      list: {
        root: path(ROOTS.admin, '/program/video-learning/list'),
        modules: path(
          ROOTS.admin,
          '/program/video-learning/list/:onlineCourseId/modules',
        ),
        sessions: path(
          ROOTS.admin,
          '/program/video-learning/list/:onlineCourseId/modules/:onlineCourseModuleId/sessions',
        ),
        topics: path(
          ROOTS.admin,
          '/program/video-learning/list/:onlineCourseId/modules/:onlineCourseModuleId/sessions/:onlineCourseSessionId/topics',
        ),
      },
      create: path(ROOTS.admin, '/program/video-learning/create'),
      details: path(ROOTS.admin, '/program/video-learning/:onlineCourseId'),
      access: path(ROOTS.admin, '/program/video-learning/access'),
      practiceSession: {
        root: path(ROOTS.admin, '/program/practice-session'),
        group: {
          root: path(ROOTS.admin, '/program/practice-session/:programId/group'),
          schedule: path(
            ROOTS.admin,
            '/program/practice-session/:programId/group/:programPracticeSessionGroupId/schedule',
          ),
        },
      },
      studentQuestion: path(
        ROOTS.admin,
        '/program/video-learning/student-question',
      ),
    },
    livestream: {
      root: path(ROOTS.admin, '/program/livestream'),
      list: path(ROOTS.admin, '/program/livestream/list'),
      module: path(
        ROOTS.admin,
        '/program/livestream/:programLivestreamId/module',
      ),
      session: path(
        ROOTS.admin,
        '/program/livestream/:programLivestreamId/module/:programLivestreamModuleId/session',
      ),
      material: path(
        ROOTS.admin,
        '/program/livestream/:programLivestreamId/module/:programLivestreamModuleId/session/:programLivestreamSessionId/material',
      ),
      access: path(ROOTS.admin, '/program/livestream/access'),
      schedule: path(ROOTS.admin, '/program/livestream/schedule'),
    },
    cost: {
      root: path(ROOTS.admin, '/program/cost'),
      detail: path(ROOTS.admin, '/program/cost/:programId'),
      categories: path(ROOTS.admin, '/program/cost/categories'),
    },
    exam: {
      root: path(ROOTS.admin, '/program/exam'),
      list: path(ROOTS.admin, '/program/exam/list'),
      preview: path(ROOTS.admin, '/program/exam/preview/:testId'),
      batchList: path(ROOTS.admin, '/program/exam/batch'),
      schedule: path(ROOTS.admin, '/program/exam/batch/:programId/schedules'),
      transcript: path(
        ROOTS.admin,
        '/program/exam/batch/:programId/transcript',
      ),
      studentResults: path(
        ROOTS.admin,
        '/program/exam/batch/:programId/schedules/:scheduleId/results',
      ),
      resultDetails: path(
        ROOTS.admin,
        '/program/exam/batch/:programId/schedules/:scheduleId/results/:userId',
      ),
      create: path(ROOTS.admin, '/program/exam/create'),
    },
    evaluation: {
      root: path(ROOTS.admin, '/program/evaluation'),
      batch: path(ROOTS.admin, '/program/evaluation/batch'),
      schedule: path(ROOTS.admin, '/program/evaluation/batch/:programId'),
      result: path(
        ROOTS.admin,
        '/program/evaluation/batch/:programId/results/:evaluationId',
      ),

      lecturer: {
        root: path(ROOTS.admin, '/program/evaluation/lecturer'),
        batchDetail: path(
          ROOTS.admin,
          '/program/evaluation/lecturer/:programId',
        ),
        lecturerResult: path(
          ROOTS.admin,
          '/program/evaluation/lecturer/:programId/results/:evaluationId',
        ),
      },
    },
    digitalSignature: {
      root: path(ROOTS.admin, '/program/digital-signature'),
      registration: path(
        ROOTS.admin,
        '/program/digital-signature/registration',
      ),
      document: {
        root: path(ROOTS.admin, '/program/digital-signature/document'),
        attendee: path(
          ROOTS.admin,
          '/program/digital-signature/document/:programId/attendee',
        ),
      },
    },
    scholarship: {
      root: path(ROOTS.admin, '/program/scholarship'),
      applicantList: path(ROOTS.admin, '/program/scholarship/:id/applicant'),
    },
    paylater: {
      root: path(ROOTS.admin, '/program/belajar-sekarang-bayar-setelah-kerja'),
      applicantList: path(
        ROOTS.admin,
        '/program/belajar-sekarang-bayar-setelah-kerja/:id/applicant',
      ),
    },
    academicSchedule: {
      root: path(ROOTS.admin, '/program/academic-schedule'),
    },
    communityPartner: {
      root: path(ROOTS.admin, '/community-partner'),
    },
    grade: {
      root: path(ROOTS.admin, '/grade'),
      mainCategory: path(ROOTS.admin, '/grade/:id/main-category'),
      subCategory: path(
        ROOTS.admin,
        '/grade/:programScoringId/main-category/:programScoringCategoryId/sub-category',
      ),
      batch: {
        root: path(ROOTS.admin, '/program/grade/batch'),
        score: path(ROOTS.admin, '/program/grade/batch/:batchId'),
      },
    },
  },
  managementSection: {
    root: path(ROOTS.admin, '/management'),
    master: {
      root: path(ROOTS.admin, '/management/master'),
      paymentMethod: path(ROOTS.admin, '/management/master/payment-method'),
      tujuanPenerimaan: path(
        ROOTS.admin,
        '/management/master/tujuan-penerimaan',
      ),
      tujuanPengeluaran: path(
        ROOTS.admin,
        '/management/master/tujuan-pengeluaran',
      ),
    },
    transaction: {
      root: path(ROOTS.admin, '/management/transaction'),
      list: path(ROOTS.admin, '/management/transaction/list'),
    },
    finance: {
      root: path(ROOTS.admin, '/management/finance'),
      purchaseOrder: path(ROOTS.admin, '/management/finance/purchase-order'),
      eFaktur: path(ROOTS.admin, '/management/finance/e-faktur'),
      edcJournal: path(ROOTS.admin, '/management/finance/edc-journal'),
      transactionInstallment: path(
        ROOTS.admin,
        '/management/finance/transaction-installment',
      ),
    },
    accounting: {
      root: path(ROOTS.admin, '/management/accounting'),
      glJournal: path(ROOTS.admin, '/management/accounting/gl-journal'),
      glGenerator: path(ROOTS.admin, '/management/accounting/gl-generator'),
      posting: path(ROOTS.admin, '/management/accounting/posting'),
    },
    promotions: {
      root: path(ROOTS.admin, '/management/promotion'),
      promo: path(ROOTS.admin, '/management/promotion/promo'),
      voucherCode: path(ROOTS.admin, '/management/promotion/voucher-code'),
      referralCode: path(ROOTS.admin, '/management/promotion/referral-code'),
      createPromo: path(ROOTS.admin, '/management/promotion/promo/create'),
      editPromo: path(ROOTS.admin, '/management/promotion/promo/:id/edit'),
    },
  },
  reportSection: {
    root: path(ROOTS.admin, '/report'),
    salesReport: path(ROOTS.admin, '/report/sales-report'),
    programSalesReport: path(ROOTS.admin, '/report/program-sales-report'),
    bankReport: path(ROOTS.admin, '/report/bank-report'),
    enquiryReport: path(ROOTS.admin, '/report/enquiry-report'),
    installmentReport: path(ROOTS.admin, '/report/installment-report'),
    installmentARReport: path(ROOTS.admin, '/report/installment-ar-report'),
    newInstallmentReport: path(
      ROOTS.admin,
      '/report/transaction-installment-new',
    ),
    suspendedInstallmentReport: path(
      ROOTS.admin,
      '/report/suspended-installment',
    ),
  },
  userSection: {
    root: path(ROOTS.admin, '/user'),
    user: {
      root: path(ROOTS.admin, '/user/list'),
      list: path(ROOTS.admin, '/user/list'),
      role: path(ROOTS.admin, '/user/role'),
      userRole: path(ROOTS.admin, '/user/user-role'),
    },
    student: {
      root: path(ROOTS.admin, '/user/student'),
      studentAnnouncement: path(ROOTS.admin, '/user/student/announcements'),
      studentInfo: path(ROOTS.admin, '/user/student/info'),
      studentReport: path(ROOTS.admin, '/user/student/report'),
      studentBirthday: path(ROOTS.admin, '/user/student/birthday'),
      studentSupport: path(ROOTS.admin, '/user/student/student-support'),
      attendees: path(ROOTS.admin, '/user/student/attendees'),
      studentList: path(ROOTS.admin, '/user/student/list'),
      studentRecommendation: path(
        ROOTS.admin,
        '/user/student/student-recommendation',
      ),
    },
    lecturer: {
      root: path(ROOTS.admin, '/user/lecturer'),
      list: path(ROOTS.admin, '/user/lecturer/list'),
    },
    branchOperator: {
      root: path(ROOTS.admin, '/user/operational'),
      list: path(ROOTS.admin, '/user/operational/list'),
    },
  },
  communitySection: {
    root: path(ROOTS.admin, '/community'),
    benefit: {
      root: path(ROOTS.admin, '/community/benefit'),
      detail: path(ROOTS.admin, '/community/benefit/:id'),
      merchants: path(ROOTS.admin, '/community/benefit/merchants'),
    },
    pacMember: {
      root: path(ROOTS.admin, '/community/pac-member'),
    },
    tribe: {
      root: path(ROOTS.admin, '/community/tribe'),
      create: path(ROOTS.admin, '/community/tribe/create'),
      edit: path(ROOTS.admin, '/community/tribe/:tribeId/edit'),
    },
    activity: {
      root: path(ROOTS.admin, '/community/activity'),
      create: path(ROOTS.admin, '/community/activity/create'),
      edit: path(ROOTS.admin, '/community/activity/:eventSlug/edit'),
    },
    jobPosting: {
      root: path(ROOTS.admin, '/community/job-posting'),
      create: path(ROOTS.admin, '/community/job-posting/create'),
      edit: path(ROOTS.admin, '/community/job-posting/:jobPostingId/edit'),
      applicant: {
        list: path(
          ROOTS.admin,
          '/community/job-posting/:jobPostingId/applicants',
        ),
        detail: path(
          ROOTS.admin,
          '/community/job-posting/:jobPostingId/applicants/:jobPostingApplicantId',
        ),
      },
    },
    alumni: {
      root: path(ROOTS.admin, '/community/alumni'),
      detail: path(ROOTS.admin, '/community/alumni/detail/:alumniId'),
    },
    company: {
      root: path(ROOTS.admin, '/community/company'),
      create: path(ROOTS.admin, '/community/company/create'),
      edit: path(ROOTS.admin, '/community/company/:companyId/edit'),
    },
    alumniContact: {
      root: path(ROOTS.admin, '/community/alumni-contact'),
      list: path(ROOTS.admin, '/community/alumni-contact/list'),
      enquiry: path(ROOTS.admin, '/community/alumni-contact/enquiry'),
      create: path(ROOTS.admin, '/community/alumni-contact/create'),
      edit: path(
        ROOTS.admin,
        '/community/alumni-contact/:alumniContactId/edit',
      ),
    },
  },
  eLearningHubSection: {
    youtube: {
      playlists: path(ROOTS.admin, '/e-learning-hub/youtube/playlists'),
      videos: path(ROOTS.admin, '/e-learning-hub/youtube/videos'),
    },
  },
  notificationSection: {
    root: path(ROOTS.admin, '/notification'),
    create: path(ROOTS.admin, '/notification/create'),
  },

  SupportSection: {
    supportTicket: {
      list: path(ROOTS.admin, '/support/support-ticket/list'),
      detail: path(ROOTS.admin, '/support/support-ticket/list/detail'),
    },
  },
};
